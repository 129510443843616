export const orderStep = {
  // Type 1 -> Nouvelle ligne    Type 2 -> Migration de ligne   Type 3 -> Achat d'équipement
  type: {
    1: {
      nom: 'Nouvelle ligne',
      statut: {
        1: {
          etape: {
            1: { // Demande de confirmation
              operation: {
                action: {
                  bouton: true,
                  couleur: 'green',
                  nom: 'order.operation.action.Confirmation_request', // Demande de confirmation
                  icon: {
                    nom: 'mdi-alert',
                    couleur: 'yellow accent-3',
                  },
                  fonction: {
                      nom: 'confirmationRequestDialog',
                      messageNo: 22,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        2: {
          etape: {
            1: { // Demande de confirmation
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.status.Confirmation_request', // En attente de confirmation
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: true, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: true,
                  couleur: 'blue',
                  nom: 'I_confirm', // En attente du numéro de numéro de téléphone
                  icon: {
                    nom: 'mdi-check',
                    couleur: 'null',
                  },
                  fonction: {
                    nom: 'orderComfirm',
                    messageNo: null,
                  },
                },
              },
            },
            3: { // Approbation responsable
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.status.Awaiting_manager_approval', // En attente d'approbation du responsable
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        3: {
          etape: {
            1: { // Fixer rendez-vous
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Make_appointment', // Fixer rendez-vous
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'makeAppointment',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            2: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Inform_client_rdv', // Informer client pour la date de rendez-vous
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'informClientDialog',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            3: {
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.operation.action.Awaiting_appointment_confirmation', // En attente de confirmation du rdv
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: true, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.Confirm_appointment', // En attente du numéro de numéro de téléphone
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'null',
                  },
                  fonction: {
                    nom: 'orderComfirmAppointment',
                    messageNo: null,
                  },
                },
              },
            },
            4: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Awaiting_phone_number', // En attente du numéro de numéro de téléphone
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'awaitingPhoneNumber',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            5: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Awaiting_order_number', // En attente du numéro de commande
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'awaitingOrderNumber',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            6: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Waiting_for_tracking_number', // En attente du numéro de repérage (Tracking number)
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'numeroDeSuiviDialog',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        6: {
          etape: {
            1: { // Informer client commande livrée
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Inform_customer_delivered_order', // Informer client commande livrée
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'informClientDialog',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        7: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Close_order', // Fermer la commande
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'close',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        8: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.operation.status.8', // Compléter
                  icon: {
                    nom: 'mdi-check-circle',
                    couleur: 'green',
                  },
                  fonction: {
                    nom: null,
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        '-1': {
          etape: {
            1: { // Annulée
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
      },
    },
    2: {
      nom: 'Migration de ligne',
      statut: {
        1: {
          etape: {
            1: { // Demande de confirmation
              operation: {
                action: {
                  bouton: true,
                  couleur: 'green',
                  nom: 'order.operation.action.Confirmation_request', // Demande de confirmation
                  icon: {
                    nom: 'mdi-alert',
                    couleur: 'yellow accent-3',
                  },
                  fonction: {
                      nom: 'confirmationRequestDialog',
                      messageNo: 22,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        2: {
          etape: {
            1: { // Demande de confirmation pour le client
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.status.Confirmation_request', // En attente de confirmation
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: true, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: true,
                  couleur: 'blue',
                  nom: 'I_confirm', // En attente du numéro de numéro de téléphone
                  icon: {
                    nom: 'mdi-check',
                    couleur: 'null',
                  },
                  fonction: {
                    nom: 'orderComfirm',
                    messageNo: null,
                  },
                },
              },
            },
            2: {
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.status.Telus_autorisation', // En attente de la confirmation de l'autorisation de transfert de ligne vers Telus
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: true, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Confirm_autorisation', // En attente du numéro de numéro de téléphone
                  icon: {
                    nom: 'mdi-check',
                    couleur: 'null',
                  },
                  fonction: {
                    nom: 'orderComfirmAutorisation',
                    messageNo: null,
                  },
                },
              },
            },
            3: { // Approbation responsable
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.status.Awaiting_manager_approval', // En attente d'approbation du responsable
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        3: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Migrate_line', // Migrer la ligne
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'migrateLineDialog',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            2: { // Fixer rendez-vous
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Make_appointment', // Fixer rendez-vous
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'makeAppointment',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            3: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Inform_client_rdv', // Informer client pour la date de rendez-vous
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'informClientDialog',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            4: {
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.operation.action.Awaiting_appointment_confirmation', // En attente de confirmation du rdv
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: true, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.Confirm_appointment', // En attente du numéro de numéro de téléphone
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'null',
                  },
                  fonction: {
                    nom: 'orderComfirmAppointment',
                    messageNo: null,
                  },
                },
              },
            },
            5: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Waiting_for_temporary_number', // En attente du numéro temporaire
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'temporaryPhoneNumber',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            6: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Awaiting_order_number', // En attente du numéro de commande
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'awaitingOrderNumber',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            7: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Request_migration', // Faire demande de migration
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'awaitingOrderNumberMigration',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        5: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Close_order', // Fermer la commande
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'migrateClose',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        7: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Close_migrate_order', // Fermer la commande
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'close',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        8: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.operation.status.8', // Compléter
                  icon: {
                    nom: 'mdi-check-circle',
                    couleur: 'green',
                  },
                  fonction: {
                    nom: null,
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        '-1': {
          etape: {
            1: { // Annulée
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
      },
    },
    3: {
      nom: 'Achat d\'équipement',
      statut: {
        1: {
          etape: {
            1: { // Inscrire le coût
              operation: {
                action: {
                  bouton: true,
                  couleur: 'green',
                  nom: 'order.operation.action.Enter_the_cost', // Inscrire le coût
                  icon: {
                    nom: 'mdi-alert',
                    couleur: 'yellow accent-3',
                  },
                  fonction: {
                      nom: 'addDevicePrice',
                      messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            2: { // Demande de confirmation
                operation: {
                  action: {
                    bouton: true,
                    couleur: 'green',
                    nom: 'order.operation.action.Confirmation_request', // Demande de confirmation
                    icon: {
                      nom: 'mdi-alert',
                      couleur: 'yellow accent-3',
                    },
                    fonction: {
                        nom: 'confirmationRequestDialog',
                        messageNo: 22,
                    },
                  },
                },
                mesCommandes: {
                  action: {
                    iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                    bouton: false,
                    couleur: null,
                    nom: null,
                    icon: null,
                    fonction: null,
                  },
                },
            },
          },
        },
        2: {
          etape: {
            1: { // Demande de confirmation
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.status.Confirmation_request', // En attente de confirmation
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: true, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: true,
                  couleur: 'blue',
                  nom: 'I_confirm', // En attente du numéro de numéro de téléphone
                  icon: {
                    nom: 'mdi-check',
                    couleur: 'null',
                  },
                  fonction: {
                    nom: 'orderComfirm',
                    messageNo: null,
                  },
                },
              },
            },
            3: { // Approbation responsable
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.status.Awaiting_manager_approval', // En attente d'approbation du responsable
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        3: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Awaiting_order_number', // En attente du numéro de commande
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'awaitingOrderNumber',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            2: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Waiting_for_tracking_number', // En attente du numéro de repérage (Tracking number)
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'numeroDeSuiviDialog',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            3: { // Fixer rendez-vous
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Make_appointment', // Fixer rendez-vous
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'makeAppointment',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            4: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Inform_client_rdv', // Informer client pour la date de rendez-vous
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'informClientDialog',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            5: {
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.operation.action.Awaiting_appointment_confirmation', // En attente de confirmation du rdv
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: true, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.Confirm_appointment', // En attente du numéro de numéro de téléphone
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'null',
                  },
                  fonction: {
                    nom: 'orderComfirmAppointment',
                    messageNo: null,
                  },
                },
              },
            },
          },
        },
        4: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Awaiting_bill_number', // En attente du numéro de facture
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'ordered',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
            2: {
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.operation.action.Waiting_SIM_card_number', // En attente du numéro de carte SIM
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: 'grey',
                  },
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: true, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.Enter_no_sim_card', // En attente du numéro de numéro de téléphone
                  icon: {
                    nom: 'mdi-check',
                    couleur: 'null',
                  },
                  fonction: {
                    nom: 'enterNoSimCard',
                    messageNo: null,
                  },
                },
              },
            },
            3: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Activate_SIM_card', // En attente du numéro de facture
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'activeSimCard',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        6: {
          etape: {
            1: { // Informer client commande livrée
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Inform_customer_delivered_order', // Informer client commande livrée
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'informClientDialog',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        7: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: true,
                  couleur: 'blue',
                  nom: 'order.operation.action.Close_order', // Fermer la commande
                  icon: {
                    nom: 'mdi-clock-time-three-outline',
                    couleur: null,
                  },
                  fonction: {
                    nom: 'close',
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        8: {
          etape: {
            1: {
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: 'order.operation.status.8', // Compléter
                  icon: {
                    nom: 'mdi-check-circle',
                    couleur: 'green',
                  },
                  fonction: {
                    nom: null,
                    messageNo: null,
                  },
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
        '-1': {
          etape: {
            1: { // Annulée
              operation: {
                action: {
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
              mesCommandes: {
                action: {
                  iconMesCommandes: false, // Faire apparaître l'îcone d'avertissement dans le bouton "Mes commandes" dans le dashboard dans le dashboard
                  bouton: false,
                  couleur: null,
                  nom: null,
                  icon: null,
                  fonction: null,
                },
              },
            },
          },
        },
      },
    },
  },
}
