var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-forms","fluid":"","tag":"section"}},[_c('validation-observer',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',[(_vm.ifRoleAdminAndRouteOrder)?_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-material-card',{staticClass:"px-5 py-3 mt-3",attrs:{"color":"primary","icon":"mdi-lock-open","title":_vm.$t('Administrator')}},[_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('Status'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{staticClass:"ml-4 mt-0",attrs:{"disabled":true,"items":_vm.statusAdmin,"item-text":"item","item-value":"valeur","persistent-hint":"","single-line":"","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.statut),callback:function ($$v) {_vm.$set(_vm.submitForm, "statut", $$v)},expression:"submitForm.statut"}})]}}],null,true)})],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-material-card',{staticClass:"px-5 py-3 mt-3",attrs:{"color":"primary","icon":"mdi-book-information-variant","title":_vm.$t('order.Status_type')}},[_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('order.Order_type'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('order.Order_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-radio-group',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"row":"","error-messages":errors,"success":valid,"required":""},on:{"change":function($event){return _vm.changeType($event)}},model:{value:(_vm.submitForm.type),callback:function ($$v) {_vm.$set(_vm.submitForm, "type", $$v)},expression:"submitForm.type"}},[_c('v-radio',{attrs:{"label":_vm.$t('order.Activation_new_number'),"value":1}}),_c('v-radio',{attrs:{"label":_vm.$t('order.transfer_number'),"value":2}})],1)]}}],null,true)}),(_vm.submitForm.type === 2)?[_c('div',{staticClass:"mt-5"},[_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('order.message_migration'))+" ")])],1),_c('validation-provider',{attrs:{"name":_vm.$t('order.Phone_number_to_migrate'),"rules":{ required:true, min:10, max:10, numeric:true, numberPhoneIsDuplicates: _vm.submitForm.no_telephone }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"maxlength":"10","placeholder":"xxxxxxxxxx","label":_vm.$t('order.Phone_number_to_migrate'),"auto-grow":"","append-icon":"mdi-phone","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.no_telephone),callback:function ($$v) {_vm.$set(_vm.submitForm, "no_telephone", $$v)},expression:"submitForm.no_telephone"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('contract.Provider'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('order.Current_provider'),"items":_vm.provider,"item-text":"valeur","item-value":"item","persistent-hint":"","single-line":"","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.migration_fournisseur),callback:function ($$v) {_vm.$set(_vm.submitForm, "migration_fournisseur", $$v)},expression:"submitForm.migration_fournisseur"}})]}}],null,true)}),(_vm.ifRouteOrder && _vm.specialProvider)?_c('validation-provider',{attrs:{"name":_vm.$t('order.Temporary_phone_number'),"rules":{ required:true, min:10, max:10, numeric:true, numberPhoneIsDuplicates: _vm.submitForm.no_telephone }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"type":"number","placeholder":"xxxxxxxxxx","label":_vm.$t('order.Temporary_phone_number'),"rows":"1","auto-grow":"","append-icon":"mdi-phone","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.no_telephone_temporaire),callback:function ($$v) {_vm.$set(_vm.submitForm, "no_telephone_temporaire", $$v)},expression:"submitForm.no_telephone_temporaire"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('order.Account_number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('order.Account_number'),"append-icon":"mdi-card-account-details","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.migration_no_contrat),callback:function ($$v) {_vm.$set(_vm.submitForm, "migration_no_contrat", $$v)},expression:"submitForm.migration_no_contrat"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('order.Name_account'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('order.Name_account'),"append-icon":"mdi-account","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.migration_responsable),callback:function ($$v) {_vm.$set(_vm.submitForm, "migration_responsable", $$v)},expression:"submitForm.migration_responsable"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('order.Address_account'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('order.Address_account'),"auto-grow":"","append-icon":"mdi-comment","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.migration_adresse),callback:function ($$v) {_vm.$set(_vm.submitForm, "migration_adresse", $$v)},expression:"submitForm.migration_adresse"}})]}}],null,true)})]:_vm._e(),_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('order.Package_type'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('order.Package_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-radio-group',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"row":"","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.forfait),callback:function ($$v) {_vm.$set(_vm.submitForm, "forfait", $$v)},expression:"submitForm.forfait"}},[_c('v-radio',{attrs:{"label":_vm.$t('order.Voice_only'),"value":1}}),_c('v-radio',{attrs:{"label":_vm.$t('order.Data_only'),"value":2}}),_c('v-radio',{attrs:{"label":_vm.$t('order.Voice_data'),"value":3}})],1)]}}],null,true)}),_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('order.User_use_number'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('order.User_use_number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"append-icon":"mdi-account","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.contrat_label),callback:function ($$v) {_vm.$set(_vm.submitForm, "contrat_label", $$v)},expression:"submitForm.contrat_label"}})]}}],null,true)})],2)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-material-card',{staticClass:"px-5 py-3 mt-3",attrs:{"color":"primary","icon":"mdi-truck-fast","title":_vm.$t('order.Pickup_Delivery')}},[(_vm.parameterPickupDelivery === '0')?_c('validation-provider',{attrs:{"name":_vm.$t('order.Pickup_Delivery'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-radio-group',{staticClass:"ml-4 mt-3",attrs:{"disabled":_vm.state === 'view',"row":"","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.livraison),callback:function ($$v) {_vm.$set(_vm.submitForm, "livraison", $$v)},expression:"submitForm.livraison"}},[_c('v-radio',{attrs:{"label":_vm.$t('order.Pickup'),"value":false}}),_c('v-radio',{attrs:{"label":_vm.$t('order.Delivery'),"value":true}})],1)]}}],null,true)}):_vm._e(),(_vm.submitForm.livraison !== null)?[(_vm.submitForm.livraison)?[_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('order.Delivery'))+" ")]),(_vm.ifRouteOrder)?_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('order.Tracking_number')},model:{value:(_vm.submitForm.livraison_no_suivi),callback:function ($$v) {_vm.$set(_vm.submitForm, "livraison_no_suivi", $$v)},expression:"submitForm.livraison_no_suivi"}}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('Name'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.livraison_adresse.nom),callback:function ($$v) {_vm.$set(_vm.submitForm.livraison_adresse, "nom", $$v)},expression:"submitForm.livraison_adresse.nom"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('FirstName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('FirstName'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.livraison_adresse.prenom),callback:function ($$v) {_vm.$set(_vm.submitForm.livraison_adresse, "prenom", $$v)},expression:"submitForm.livraison_adresse.prenom"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('client.Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('client.Address'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.livraison_adresse.adresse),callback:function ($$v) {_vm.$set(_vm.submitForm.livraison_adresse, "adresse", $$v)},expression:"submitForm.livraison_adresse.adresse"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('client.City'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('client.City'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.livraison_adresse.ville),callback:function ($$v) {_vm.$set(_vm.submitForm.livraison_adresse, "ville", $$v)},expression:"submitForm.livraison_adresse.ville"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('client.City'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"items":_vm.provinces,"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.livraison_adresse.province),callback:function ($$v) {_vm.$set(_vm.submitForm.livraison_adresse, "province", $$v)},expression:"submitForm.livraison_adresse.province"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('client.ZIP_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('client.ZIP_code'),"error-messages":errors,"success":valid,"placeholder":"H0H 0H0","required":""},model:{value:(_vm.submitForm.livraison_adresse.code_postal),callback:function ($$v) {_vm.$set(_vm.submitForm.livraison_adresse, "code_postal", $$v)},expression:"submitForm.livraison_adresse.code_postal"}})]}}],null,true)})]:[_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('order.Pickup'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Counter'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{staticClass:"ml-4 mt-0",attrs:{"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.ComptoirId),callback:function ($$v) {_vm.$set(_vm.submitForm, "ComptoirId", $$v)},expression:"submitForm.ComptoirId"}},[_vm._l((_vm.getComptoirList),function(item,i){return [(!item)?_c('v-divider',{key:("divider-" + i)}):_c('v-list-item',{key:("item-" + i),attrs:{"disabled":_vm.state === 'view' || item.id === _vm.submitForm.ComptoirId || _vm.getComptoirList.length === 1,"value":item.id,"active-class":"green--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"green"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item[_vm.language])}})],1)]}}],null,true)})]})],2)],1)]}}],null,true)})]]:_vm._e()],2)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-material-card',{staticClass:"px-5 py-3 mt-3",attrs:{"color":"primary","icon":"mdi-currency-usd","title":_vm.$t('Billing')}},[(_vm.submitForm.card_info)?[_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{staticClass:"ml-4 mt-0",attrs:{"required":"","append-icon":"mdi-credit-card"}},[_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"disabled":true,"input-value":true,"color":"green"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.submitForm.card_info.nom_titulaire))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(((_vm.$t('profile.automatic_payment.Credit_card_ending')) + " " + (_vm.submitForm.card_info.dernier_chiffre))))])],1)],1)],1)],1)]:[(_vm.parameterModePaiement === '3')?[_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('order.Payment_mode'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('order.Payment_mode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-radio-group',{staticClass:"ml-4 mt-0",attrs:{"disabled":_vm.state === 'view',"row":"","error-messages":errors,"success":valid,"required":"","append-icon":"mdi-currency-usd"},model:{value:(_vm.submitForm.paiement_automatique),callback:function ($$v) {_vm.$set(_vm.submitForm, "paiement_automatique", $$v)},expression:"submitForm.paiement_automatique"}},[_c('v-radio',{attrs:{"label":_vm.$t('order.Manual_payment'),"value":false}}),_c('v-radio',{attrs:{"label":_vm.$t('order.Automatic_payment'),"value":true}})],1)]}}],null,true)})]:_vm._e(),(_vm.submitForm.paiement_automatique)?[_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('order.Credit_card'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('order.Credit_card'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-list',{attrs:{"shaped":""}},[(!_vm.client.Inscription_paiements.length)?_c('div',{staticClass:"text-center"},[_c('router-link',{staticClass:"text-h3 mb-1",attrs:{"to":{ name: 'profile'}}},[_vm._v(" "+_vm._s(_vm.$t('profile.automatic_payment.Add_card'))+" ")])],1):_vm._e(),_c('v-list-item-group',{staticClass:"ml-4 mt-0",attrs:{"error-messages":errors,"success":valid,"required":"","append-icon":"mdi-credit-card"},model:{value:(_vm.submitForm.InscriptionPaiementId),callback:function ($$v) {_vm.$set(_vm.submitForm, "InscriptionPaiementId", $$v)},expression:"submitForm.InscriptionPaiementId"}},[_vm._l((_vm.client.Inscription_paiements),function(card,i){return [(!card)?_c('v-divider',{key:("divider-" + i)}):_c('v-list-item',{key:("item-" + i),attrs:{"disabled":_vm.state === 'view',"value":card.id,"active-class":"green--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"disabled":active,"input-value":active,"color":"green"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(card.nom_titulaire))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(((_vm.$t('profile.automatic_payment.Credit_card_ending')) + " " + (card.dernier_chiffre))))])],1)]}}],null,true)})]})],2)],1)]}}],null,true)})]:_vm._e(),(_vm.submitForm.paiement_automatique !== null)?_c('div',{staticClass:"mt-5 ml-4"},[_c('v-icon',[_vm._v(" mdi-credit-card ")]),_c('span',{staticClass:"red--text ml-1"},[_vm._v(_vm._s(_vm.submitForm.paiement_automatique ? _vm.$t('order.My_monthly_charges_auto').toUpperCase() : _vm.$t('order.My_monthly_charges_manuel').toUpperCase()))])],1):_vm._e()]],2)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-material-card',{staticClass:"px-5 py-3 mt-3",attrs:{"color":"primary","icon":"mdi-comment","title":_vm.$t('Comment')}},[_c('v-textarea',{staticClass:"ml-4 mt-3",attrs:{"disabled":_vm.state === 'view',"label":_vm.$t('order.comment_description'),"rows":"1","auto-grow":"","append-icon":"mdi-comment"},model:{value:(_vm.submitForm.note),callback:function ($$v) {_vm.$set(_vm.submitForm, "note", $$v)},expression:"submitForm.note"}})],1)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.state !== 'view')?_c('v-btn',{staticClass:"mt-3",attrs:{"disabled":invalid,"color":"primary"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t('Validate'))+" ")]):_vm._e()],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }