<template>
  <v-container
    id="extended-tables"
    fluid
    tag="section"
  >
    <div class="py-3" />
    <base-material-card
      color="success"
      icon="mdi-cart-outline"
      inline
      :title="$t('order.My_orders')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="tableHeaders"
        :items="ubrResponsableOrders"
      >
        <!-- Mode de paiement-->
        <template
          slot="item.mode_paiement"
          slot-scope="props"
        >
          <!-- Payable par code de facturation( UQAM UBR)-->
          <div v-if="props.item.mode_paiement === 1">
            <v-icon
              color="green"
              large
            >
              mdi-book-open-variant
            </v-icon>
            {{ getOrderCodeName }}
          </div>

          <!-- Carte de crédit-->
          <div v-if="props.item.mode_paiement === 2">
            <v-icon
              color="primary"
              large
            >
              mdi-credit-card
            </v-icon>
            {{ $t('order.Credit_card') }}
          </div>
        </template>

        <!-- Type (Description)-->
        <!-- Nouvelle ligne-->
        <template
          slot="item.type"
          slot-scope="props"
        >
          <div v-if="props.item.type === 1">
            <v-icon
              color="grey lighten-1"
              large
            >
              mdi-sim-outline
            </v-icon>
            {{ $t('order.type.1') }}
          </div>
          <!-- Migration-->
          <div v-if="props.item.type === 2">
            <v-icon
              color="grey lighten-1"
              large
            >
              mdi-sim
            </v-icon>
            {{ $t('order.type.2') }}
          </div>
          <!-- Achat d'équipement-->
          <div v-if="props.item.type === 3">
            <div>
              <v-icon
                color="grey lighten-1"
                large
              >
                mdi-phone
              </v-icon>
              {{ $t('order.type.3') }}
            </div>
            <!-- Description-->
            <div>
              <span>Description: {{ props.item.description_equipement }}</span>
            </div>
            <!-- Coût-->
            <div>
              <span v-if="props.item.cout_facture">{{ $t('contract.calls_detail.Cost') }}: {{ props.item.cout_facture }} $</span>
            </div>
          </div>
        </template>

        <!-- Nom du Client-->
        <template
          slot="item.Client.nom"
          slot-scope="props"
        >
          <span>{{ props.item.Client.prenom }} {{ props.item.Client.nom }}</span>
        </template>

        <!-- Statut-->
        <template
          slot="item.statut"
        >
          <span>{{ $t('order.status.Awaiting_manager_approval') }}</span>
        </template>

        <!-- Intervention (Bouton spécial)-->
        <template
          slot="item.intervention"
          slot-scope="props"
        >
          <!--  Approuver-->
          <v-btn
            class="ml-2"
            elevation="2"
            small
            color="blue"
            @click="approve(props.item)"
          >
            {{ $t('order.Approve') }}
          </v-btn>

          <!--  Refuser-->
          <v-btn
            class="ml-2"
            elevation="2"
            small
            color="red"
            @click="refuse(props.item)"
          >
            {{ $t('order.Refuse') }}
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  import MessagesService from '@/services/01Cell/MessagesService'
  // Mixins
  import { MyOrders } from '@/mixins/Client/Order/MyOrders'
  // Other
  import { EventBus } from '@/utils/event-bus'
  import Swal from 'sweetalert2'

  export default {
    name: 'ResponsibleOrders',
    mixins: [MyOrders],
    props: {
      ubrResponsableOrders: Array,
    },
    data () {
      return {
        language: this.$i18n.t('Language'),
        tableHeaders: [
          { text: 'No', value: 'id', sortable: true, class: 'text-h4 primary--text', width: 100 },
          { text: this.$i18n.t('order.Payment_mode'), value: 'mode_paiement', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Description', value: 'type', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('order.Order_by'), value: 'Client.nom', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Status'), value: 'statut', sortable: true, class: 'text-h4 primary--text' },
          { text: '', value: 'intervention', sortable: true, class: 'text-h4 primary--text' },
        ],
      }
    },

    computed: {
    },

    methods: {
      approve (item) {
        Swal.fire({
          title: `${this.$i18n.t('order.swal.title.I_approve_order')}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('order.Approve'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Start Spinner
              this.$loading.show()
              // Statut et Étape
              const { statut, etape } = this.getNextStatutStep(item)
              // On confirme la commande
              const data = { date_approuvee_annulee: Date.now(), statut, etape }
              await CommandesService.update(data, item.id)
              // On update le client
              EventBus.$emit('update-order')
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      refuse (item) {
        Swal.fire({
          title: `${this.$i18n.t('order.swal.title.I_refuse_order')}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('order.Refuse'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Start Spinner
              this.$loading.show()
              // Récupération des messages
              const messages = await MessagesService.multiple({ messages: [24, 11] })
              const message = messages.find(item => item.no === 24)
              const signature = messages.find(item => item.no === 11)

              // Sujet
              const subject = message[`sujet_${this.language}`].replace(/<\/?[^>]+(>|$)/g, '')
              // Message
              const messageHtml = message[this.language].replace('[|SIGNATURE|]', signature[this.language])

              // On change le statut de la commande
              const updateCommande = {
                statut: -1,
                etape: 1,
                date_approuvee_annulee: Date.now(),
                sendMail: true,
                note: `${item.note} [commande annulée par le responsable]`,
                mail: {
                  mailBody: messageHtml,
                  mailSujet: subject,
                  mailTo: item.Client.email,
                  commandePdf: true,
                },
              }

              await CommandesService.confirmationRequest(item.id, updateCommande)

              // On update le client
              EventBus.$emit('update-order')
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
    },
  }
</script>
