<template>
  <validation-observer
    ref="obs"
    v-slot="{ invalid, handleSubmit }"
  >
    <form>
      <!-- Comptoir-->
      <v-subheader class="display-1">
        {{ $t('Counter') }}
      </v-subheader>

      <v-row>
        <!-- Comptoir-->
        <v-col
          cols="12"
          md="12"
        >
          <validation-provider
            v-slot="{ errors, valid }"
            :name="$t('Counter')"
            rules="required"
          >
            <v-list
              shaped
            >
              <v-list-item-group
                v-model="submitForm.ComptoirId"
                class="ml-4 mt-0"
                :error-messages="errors"
                :success="valid"
                required
              >
                <template v-for="(item, i) in pickupComptoir">
                  <v-divider
                    v-if="!item"
                    :key="`divider-${i}`"
                  />

                  <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :disabled="item.id === submitForm.ComptoirId"
                    :value="item.id"
                    active-class="green--text text--accent-4"
                    @change="getPickupHours(item.id, true)"
                  >
                    <template #default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="green"
                        />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title v-html="item[language]" />
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </validation-provider>
        </v-col>
      </v-row>

      <!-- Date et heure-->
      <v-subheader class="display-1">
        {{ $t('contract.calls_detail.Date_hour') }}
      </v-subheader>

      <v-row>
        <!-- Date-->
        <v-col
          cols="12"
          md="6"
        >
          <!-- Date de cueillette-->
          <v-date-picker
            v-model="submitForm.date"
            width="300"
            color="primary"
            class="ml-4 mt-0"
            :allowed-dates="allowedDates"
            :min="getDatePickup(administrator)"
            @change="getPickupHours(submitForm.ComptoirId, true)"
          />
        </v-col>

        <!-- Heure-->
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            flat
            rounded="lg"
            width="300"
            class="ml-3"
          >
            <v-toolbar
              dark
              color="primary"
              height="88"
              elevation="0"
              dense
            >
              <v-toolbar-title>
                <h1 class="h1 font-weight-bold mt-5 mr-4">
                  {{ submitForm.time }}
                </h1>
              </v-toolbar-title>
            </v-toolbar>

            <!-- Loading-->
            <template v-if="timeLoading">
              <br>
              <br>
              <br>
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="purple"
                />
              </div>
            </template>

            <!-- Heures-->
            <template v-else>
              <v-card-text class="ml-4">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Pickup')"
                  rules="required"
                >
                  <v-chip-group
                    v-model="submitForm.time"
                    active-class="primary--text text--accent-4"
                    column
                    :error-messages="errors"
                    :success="valid"
                  >
                    <v-chip
                      v-for="hour in hoursPickUp"
                      :key="hour.id"
                      :disabled="hour.disabled"
                      :value="hour.time"
                    >
                      {{ hour.time }}
                    </v-chip>
                  </v-chip-group>
                </validation-provider>
              </v-card-text>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </form>
    <!-- Submit-->
    <v-btn
      :disabled="invalid"
      class="mt-3"
      color="success"
      @click="handleSubmit(submit)"
    >
      {{ $t('Validate') }}
    </v-btn>
  </validation-observer>
</template>
<script>
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  import ListService from '@/services/01Cell/ListService'
  import MessagesService from '@/services/01Cell/MessagesService'
  import MailerService from '@/services/MailerService'
  // Mixins
  import { ordersPickupDate } from '@/mixins/Client/Order/ordersPickupDate'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { EventBus } from '@/utils/event-bus'
  import { format, parseISO, getUnixTime } from 'date-fns'
  import Swal from 'sweetalert2'

  export default {
    name: 'ClientCommandeDemoUqamDialogMakeAppointment',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [ordersPickupDate],
    data () {
      return {
        language: this.$i18n.t('Language'),
        submitForm: {
          date: null,
          time: null,
        },
        pickupComptoir: [],
        administrator: false,
        changeStep: false,
        oldDate: null,
      }
    },

    computed: {
    },

    methods: {
      async getData (data, administrator, changeStep) {
        this.administrator = administrator
        this.changeStep = changeStep

        // Start Spinner
        this.$loading.show()
        try {
          this.submitForm = data
          this.oldDate = parseISO(this.submitForm.rdv_date)
          if (this.submitForm.rdv_date) this.submitForm.date = format(parseISO(this.submitForm.rdv_date), 'yyyy-MM-dd')
          if (this.submitForm.rdv_date) this.submitForm.time = format(parseISO(this.submitForm.rdv_date), 'HH:mm')
          // On charger les heures du comptoir
          this.getPickupHours(this.submitForm.ComptoirId, false)
          // On récupère des comptoirs
          this.pickupComptoir = await ListService.search('commandes_comptoir')
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async submit () {
        // Close Dialog
        this.$emit('close-dialog')

        // Start Spinner
        this.$loading.show()
        try {
          // Date
          const dateTime = parseISO(this.submitForm.date + ' ' + this.submitForm.time, 'DD/MM/YYYY HH:mm')

          let etape
          if (this.submitForm.type === 1) {
            etape = 2
          } else if (this.submitForm.type === 2) {
            etape = 3
          } else if (this.submitForm.type === 3) {
            etape = 4
          }

          // Si gestionnaire, on mets la date dans l'objet 'rdv_date_init' et 'rdv_date'
          // Viens du module 'components/Client/Commande/Commande.vue'
          if (this.administrator) {
            let commande
            // Si on dois changer de step
            if (this.changeStep) {
              commande = {
                rdv_date: dateTime,
                rdv_date_init: dateTime,
                statut: 3,
                etape,
              }
            } else {
              // On ne change pas le step. Le gestionnaire fait un changement de date de rdv. On dois avertir l'utilisateur du changement de date
              commande = {
                rdv_date: dateTime,
                rdv_date_init: dateTime, // Sert pour que l'utilisateur puisse sélectionner une date ultérieur de ce qu'il a choisi
              }

              // Si la date est différente
              if (getUnixTime(this.oldDate) !== getUnixTime(dateTime)) {
                // Envoie du courriel à l'utilisateur pour dire que la date de rdz a changée
                // Récupération des messages de changement de date
                const message = await MessagesService.replace(30, this.submitForm.Client.langue)

                const messageHtml = message.body.replace('[|COMMANDES_CLIENT_PRENOM|]', this.submitForm.Client.prenom.charAt(0).toUpperCase() + this.submitForm.Client.prenom.slice(1))
                  .replace('[|COMMANDES_CLIENT_NOM|]', this.submitForm.Client.nom.charAt(0).toUpperCase() + this.submitForm.Client.nom.slice(1))
                  .replace('[|RDV_DATE|]', dateTime ? format(dateTime, `EEEE, d MMMM yyyy ${this.$i18n.t('time.at')} H:mm`, { locale: window.dateFnsLocales[this.submitForm.Client.langue] }) : '')

                // Envoir du courriel
                await MailerService.sendMail({
                  to: this.submitForm.Client.email,
                  subject: message.subject,
                  html: messageHtml,
                })

                // Stop Spinner
                this.$loading.hide()

                // Swal pour dire que un courriel a été envoyé à l'utilisateur
                Swal.fire(
                  'Attention !',
                  `${this.$i18n.t('order.swal.text.Email_sent_appointment_change')}`,
                  'info',
                )
              }
            }

            const response = await CommandesService.update(commande, this.submitForm.id)
            // Stop Spinner
            this.$loading.hide()
            // On update la liste dans le composant 'OperationCommande.vue'
            EventBus.$emit('update-commande', response)
          } else {
            // Viens du module 'components/Client/Order/MyOrders.vue'
            this.submitForm.rdv_date = dateTime

            // Si la personne n'a pas encore confirmé son rdv, on affiche pas le dialog pour ajouter l'évènement au calendrier
            if (!this.submitForm.rdv_confirme) return this.$emit('submit', this.submitForm)

            // Si la date à changé, on demande à l'utilisateur d'ajouter l'évènement au calendrier
            if (getUnixTime(this.oldDate) !== getUnixTime(dateTime)) {
              // eslint-disable-next-line vue/custom-event-name-casing
              this.$emit('submitAddToCalendar', this.submitForm)
            } else {
              this.$emit('submit', this.submitForm)
            }
          }
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
